<template>
  <div>
    <!-- 顶部 -->
    <div class="topBox">
      <div style="margin-left: 40px; margin-top: 20px">
        <div style="font-size: 24px; font-weight: 700; margin-bottom: 8px">
          {{ checkInfo.taskName }}
        </div>
        <div style="font-size: 16px; color: gray; font-weight: 700">
          （任务时间：{{ handleFormatDateFunc(checkInfo.startTime) }}~{{
            handleFormatDateFunc(checkInfo.endTime)
          }}）
        </div>
      </div>
      <div style="margin-top: 25px; margin-right: 40px">
        <el-button
          v-if="!tempTaskInfo"
          @click="handleReturnPage"
          style="height: 50px; width: 70px; text-align: center"
          >返回</el-button
        >
        <el-button
          v-if="taskSubmitStatus === 1 && !tempTaskInfo"
          type="primary"
          style="margin-left: 20px; height: 50px; width: 120px"
          @click="handleSubmitCheckResultBtnClick"
          >提交盘点结果</el-button
        >
        <div v-if="tempTaskInfo">
          <div style="margin-bottom: 6px">提交人：{{ tempTaskInfo.submitterName ? tempTaskInfo.submitterName : '/' }}</div>
          <div>提交时间：{{ tempTaskInfo.submitTime | dayFilters }}</div>
        </div>
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-between; margin-top: 20px"
    >
      <!-- 左部 -->
      <div class="content-left">
        <div style="margin: 20px 10px">
          <div
            v-for="(item, i) in labRoomList"
            :key="i"
            @click="handleSelectLabRoom(item, i)"
            class="labRoomItem"
            :style="{
              backgroundColor: item.isSelected ? 'rgb(128, 255, 255)' : '',
            }"
          >
            <div style="width: 130px;text-align: left">{{ item.sysOrgSchoolName }}</div>
            <div
              
              :style="{
                color:
                  item.isSelected && item.inventoryStatus == 1
                    ? '#00f'
                    : item.isSelected && !item.inventoryStatus == 1
                    ? 'red'
                    : '#000',
              }"
            >
              {{ item.inventoryStatus === 1 ? "已盘点" : "待盘点" }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右部 -->
      <div class="content-right">
        <!-- 第一行 -->
        <div class="content-right-one">
          <div style="display: flex">
            <div class="checkNum">
              <div style="font-size: 18px; margin-left: 20px">盘盈数</div>
              <div class="checkNum-one">{{ materialMoreNum }}</div>
            </div>
            <div class="checkNum-two">
              <div style="font-size: 18px; margin-left: 20px">盘亏数</div>
              <div class="checkLoss">{{ materialLossNum }}</div>
            </div>
          </div>
          <div>
            <el-button
              v-if="taskSubmitStatus === 1 && !tempTaskInfo"
              type="primary"
              @click="handleSaveCheckResultBtnClick"
              >保存</el-button
            >
          </div>
        </div>
        <!-- 表格区域 -->
        <el-row
          style="
            margin-top: 20px;
            height: 600px;
            overflow: hidden;
            overflow-y: scroll;
          "
        >
          <el-table
            border
            height="600"
            :data="checkDetailList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
            ></el-table-column>
            <el-table-column
              label="编号"
              width="100"
              prop="internationCode"
            ></el-table-column>
            <el-table-column label="名称" prop="materialName"></el-table-column>
            <el-table-column
              label="规格型号功能"
              prop="materialSpecifications"
            ></el-table-column>
            <el-table-column
              label="单位"
              width="70"
              prop="materialUnit"
            ></el-table-column>
            <el-table-column
              label="参考单价"
              width="70"
              prop="materialUnitPrice"
            ></el-table-column>
            <el-table-column label="是否危化品" width="70">
              <template slot-scope="scope">
                <span>{{ scope.row.materialHasDanger ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否消耗品" width="70">
              <template slot-scope="scope">
                <span>{{
                  scope.row.materialHasConsumables ? "是" : "否"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="存放位置"
              prop="sysOrgSchoolRoomName"
            ></el-table-column>
            <el-table-column
              label="库存数量"
              width="50"
              prop="stockNum"
            ></el-table-column>
            <el-table-column label="实际数量" width="100" prop="actualNum">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.actualNum"
                  type="number"
                  :min="0"
                  :max="999"
                  onkeyup="this.value=this.value.replace(/\s/g,'')"
                  class="inputBox"
                  :disabled="taskSubmitStatus !== 1 || tempTaskInfo"
                />
              </template>
            </el-table-column>
            <el-table-column label="具体原因" prop="inventoryReason">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.inventoryReason"
                  type="textarea"
                  :rows="1"
                  placeholder=""
                  onkeyup="this.value=this.value.replace(/\s/g,'')"
                  class="inputBox"
                  :class="{
                    redBox: scope.row.redBox && !scope.row.inventoryReason,
                  }"
                  :disabled="taskSubmitStatus !== 1 || tempTaskInfo"
                />
              </template>
            </el-table-column>
            <el-table-column label="状态" width="70">
              <template slot-scope="scope">
                <span v-if="!scope.row.actualNum">待盘点</span>
                <span v-else-if="scope.row.stockNum == scope.row.actualNum"
                  >正常</span
                >
                <span
                  v-else-if="scope.row.stockNum > scope.row.actualNum"
                  style="color: red"
                  >盘亏</span
                >
                <span v-else style="color: green">盘盈</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCheckAddressApi,
  getLabRoomCheckListApi,
  submitCheckTaskApi,
  saveCheckResultApi,
  getTaskCheckAccountListApi,
} from "@/api/materialManage/checkRecord.js";
import dayjs from "dayjs";
import { formatDate } from "@/utils/util";
export default {
  props: {
    tempTaskInfo: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      taskSubmitStatus: "",
      labRoomList: [],
      checkDetailList: [],
      checkInfo: "",
    };
  },
  created() {
    if (this.$route.query.checkInfo) {
      this.checkInfo = JSON.parse(this.$route.query.checkInfo);
    } else {
      this.checkInfo = this.tempTaskInfo;
    }
    console.log(this.checkInfo)
    this.taskSubmitStatus = this.checkInfo.taskSubmitStatus;
    this.fetchCheckLabRoomInfo();
  },
  computed: {
    materialLossNum() {
      return this.checkDetailList.filter((item) => {
        if (item.actualNum) {
          return item.stockNum > item.actualNum;
        }
      }).length;
    },
    materialMoreNum() {
      return this.checkDetailList.filter((item) => {
        if (item.actualNum) {
          return item.stockNum < item.actualNum;
        }
      }).length;
    },
  },
  methods: {
    // 获取盘点位置
    fetchCheckLabRoomInfo() {
      getCheckAddressApi(this.checkInfo.taskId).then((res) => {
        if (res.success) {
          this.labRoomList = res.data.map((item, i) => {
            return {
              ...item,
              isSelected: i === 0,
            };
          });
          this.fetchLabRoomData();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取实验室的表格数据
    fetchLabRoomData() {
      let sysOrgSchoolRoomId;
      this.labRoomList.forEach((item) => {
        if (item.isSelected) {
          sysOrgSchoolRoomId = item.sysOrgSchoolRoomId;
        }
      });
      let fd = new FormData();
      fd.append("sysOrgSchoolRoomId", sysOrgSchoolRoomId);
      fd.append("taskId", this.checkInfo.taskId);
      getLabRoomCheckListApi(fd).then((res) => {
        if (res.success) {
          this.checkDetailList = res.data.map(it=>({
            ...it,
            // actualNum: it.stockNum
          }));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 选择实验室
    handleSelectLabRoom(item, index) {
      this.labRoomList.forEach((item) => {
        item.isSelected = false;
      });
      this.labRoomList[index].isSelected = true;
      let fd = new FormData();
      fd.append("sysOrgSchoolRoomId", item.sysOrgSchoolRoomId);
      fd.append("taskId", this.checkInfo.taskId);
      getLabRoomCheckListApi(fd).then((res) => {
        if (res.success) {
          this.checkDetailList = res.data.map(it=>({
            ...it,
            // actualNum: it.stockNum
          }));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 返回上一页
    handleReturnPage() {
      this.$router.back();
    },
    // 提交盘点结果
    handleSubmitCheckResultBtnClick() {
      let result = false;
      this.labRoomList.forEach((item) => {
        if (item.inventoryStatus == 0) {
          result = true;
        }
      });
      if (result) {
        this.$message.error("请完成盘点");
        return;
      }
      submitCheckTaskApi(this.checkInfo.taskId).then((res) => {
        if (res.success) {
          this.$message.success("盘点结果提交成功!");
          this.handleReturnPage();
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 保存盘点结果
    handleSaveCheckResultBtnClick() {
      let result = false;
      this.checkDetailList.forEach((item) => {
        if (item.actualNum != item.stockNum && !item.inventoryReason) {
          result = true;
          item.redBox = true;
        }
      });
      if (result) {
        this.$forceUpdate();
        this.$message.error("请输入具体原因");
        return;
      } else {
        let arr = this.checkDetailList.map((item) => ({
          taskId: this.checkInfo.taskId,
          actualNum: item.actualNum,
          inventoryReason: item.inventoryReason,
          inventoryStatus: this.handleInventoryStatusFunc(item),
          taskDetailId: item.taskDetailId,
        }));
        // console.log(arr);
        saveCheckResultApi(arr).then((res) => {
          if (res.success) {
            this.$message.success("保存成功!");
            this.fetchCheckLabRoomInfo();
          } else {
            this.$message.error(res.msg);
            return;
          }
        });
      }
    },
    handleInventoryStatusFunc(item) {
      if (!item.actualNum) return 0;
      if (item.actualNum == item.stockNum) return 1;
      if (item.actualNum > item.stockNum) return 2;
      if (item.actualNum < item.stockNum) return 3;
    },
    // 格式化日期
    handleFormatDateFunc(timestamp) {
      let time = new Date(timestamp);
      return formatDate(time, "yyyy/MM/dd");
    },
  },
  filters: {
    dayFilters(val) {
      return val ? dayjs(val).format("YYYY年MM月DD日 HH:mm:ss") : "/";
    },
  },
};
</script>

<style lang="scss" scoped>
.topBox {
  height: 100px;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}
.content-left {
  width: 15%;
  height: 700px;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  overflow-y: scroll;
}
.labRoomItem {
  padding:12px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px;
  border: 1px solid rgb(170, 170, 170);
  display: flex;
  justify-content: space-between;
}
.content-right {
  width: 81%;
  padding: 20px;
  height: 660px;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 20px;
}
.content-right-one {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
}
.checkNum {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  background-color: rgb(220, 251, 172);
  border-radius: 15px;
}
.checkNum-one {
  width: 70px;
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
}
.checkNum-two {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  background-color: rgb(252, 222, 182);
  border-radius: 15px;
  margin-left: 20px;
}
.checkLoss {
  width: 70px;
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
}
::v-deep .redBox {
  .el-input__inner {
    border-color: red;
  }
}
::v-deep .inputBox {
  .el-input__inner {
    border-radius: 20px;
  }
  .el-textarea__inner {
    border-radius: 20px;
    height: 38px;
    padding-top: 7px;
  }
}
</style>
