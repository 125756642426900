var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"topBox"},[_c('div',{staticStyle:{"margin-left":"40px","margin-top":"20px"}},[_c('div',{staticStyle:{"font-size":"24px","font-weight":"700","margin-bottom":"8px"}},[_vm._v(" "+_vm._s(_vm.checkInfo.taskName)+" ")]),_c('div',{staticStyle:{"font-size":"16px","color":"gray","font-weight":"700"}},[_vm._v(" （任务时间："+_vm._s(_vm.handleFormatDateFunc(_vm.checkInfo.startTime))+"~"+_vm._s(_vm.handleFormatDateFunc(_vm.checkInfo.endTime))+"） ")])]),_c('div',{staticStyle:{"margin-top":"25px","margin-right":"40px"}},[(!_vm.tempTaskInfo)?_c('el-button',{staticStyle:{"height":"50px","width":"70px","text-align":"center"},on:{"click":_vm.handleReturnPage}},[_vm._v("返回")]):_vm._e(),(_vm.taskSubmitStatus === 1 && !_vm.tempTaskInfo)?_c('el-button',{staticStyle:{"margin-left":"20px","height":"50px","width":"120px"},attrs:{"type":"primary"},on:{"click":_vm.handleSubmitCheckResultBtnClick}},[_vm._v("提交盘点结果")]):_vm._e(),(_vm.tempTaskInfo)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"6px"}},[_vm._v("提交人："+_vm._s(_vm.tempTaskInfo.submitterName ? _vm.tempTaskInfo.submitterName : '/'))]),_c('div',[_vm._v("提交时间："+_vm._s(_vm._f("dayFilters")(_vm.tempTaskInfo.submitTime)))])]):_vm._e()],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","margin-top":"20px"}},[_c('div',{staticClass:"content-left"},[_c('div',{staticStyle:{"margin":"20px 10px"}},_vm._l((_vm.labRoomList),function(item,i){return _c('div',{key:i,staticClass:"labRoomItem",style:({
            backgroundColor: item.isSelected ? 'rgb(128, 255, 255)' : '',
          }),on:{"click":function($event){return _vm.handleSelectLabRoom(item, i)}}},[_c('div',{staticStyle:{"width":"130px","text-align":"left"}},[_vm._v(_vm._s(item.sysOrgSchoolName))]),_c('div',{style:({
              color:
                item.isSelected && item.inventoryStatus == 1
                  ? '#00f'
                  : item.isSelected && !item.inventoryStatus == 1
                  ? 'red'
                  : '#000',
            })},[_vm._v(" "+_vm._s(item.inventoryStatus === 1 ? "已盘点" : "待盘点")+" ")])])}),0)]),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"content-right-one"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"checkNum"},[_c('div',{staticStyle:{"font-size":"18px","margin-left":"20px"}},[_vm._v("盘盈数")]),_c('div',{staticClass:"checkNum-one"},[_vm._v(_vm._s(_vm.materialMoreNum))])]),_c('div',{staticClass:"checkNum-two"},[_c('div',{staticStyle:{"font-size":"18px","margin-left":"20px"}},[_vm._v("盘亏数")]),_c('div',{staticClass:"checkLoss"},[_vm._v(_vm._s(_vm.materialLossNum))])])]),_c('div',[(_vm.taskSubmitStatus === 1 && !_vm.tempTaskInfo)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSaveCheckResultBtnClick}},[_vm._v("保存")]):_vm._e()],1)]),_c('el-row',{staticStyle:{"margin-top":"20px","height":"600px","overflow":"hidden","overflow-y":"scroll"}},[_c('el-table',{attrs:{"border":"","height":"600","data":_vm.checkDetailList,"header-cell-style":{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"编号","width":"100","prop":"internationCode"}}),_c('el-table-column',{attrs:{"label":"名称","prop":"materialName"}}),_c('el-table-column',{attrs:{"label":"规格型号功能","prop":"materialSpecifications"}}),_c('el-table-column',{attrs:{"label":"单位","width":"70","prop":"materialUnit"}}),_c('el-table-column',{attrs:{"label":"参考单价","width":"70","prop":"materialUnitPrice"}}),_c('el-table-column',{attrs:{"label":"是否危化品","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.materialHasDanger ? "是" : "否"))])]}}])}),_c('el-table-column',{attrs:{"label":"是否消耗品","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.materialHasConsumables ? "是" : "否"))])]}}])}),_c('el-table-column',{attrs:{"label":"存放位置","prop":"sysOrgSchoolRoomName"}}),_c('el-table-column',{attrs:{"label":"库存数量","width":"50","prop":"stockNum"}}),_c('el-table-column',{attrs:{"label":"实际数量","width":"100","prop":"actualNum"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{staticClass:"inputBox",attrs:{"type":"number","min":0,"max":999,"onkeyup":"this.value=this.value.replace(/\\s/g,'')","disabled":_vm.taskSubmitStatus !== 1 || _vm.tempTaskInfo},model:{value:(scope.row.actualNum),callback:function ($$v) {_vm.$set(scope.row, "actualNum", $$v)},expression:"scope.row.actualNum"}})]}}])}),_c('el-table-column',{attrs:{"label":"具体原因","prop":"inventoryReason"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{staticClass:"inputBox",class:{
                  redBox: scope.row.redBox && !scope.row.inventoryReason,
                },attrs:{"type":"textarea","rows":1,"placeholder":"","onkeyup":"this.value=this.value.replace(/\\s/g,'')","disabled":_vm.taskSubmitStatus !== 1 || _vm.tempTaskInfo},model:{value:(scope.row.inventoryReason),callback:function ($$v) {_vm.$set(scope.row, "inventoryReason", $$v)},expression:"scope.row.inventoryReason"}})]}}])}),_c('el-table-column',{attrs:{"label":"状态","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.actualNum)?_c('span',[_vm._v("待盘点")]):(scope.row.stockNum == scope.row.actualNum)?_c('span',[_vm._v("正常")]):(scope.row.stockNum > scope.row.actualNum)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("盘亏")]):_c('span',{staticStyle:{"color":"green"}},[_vm._v("盘盈")])]}}])})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }